.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.topo{
	position: relative;
	z-index: 2000;
}
.menu__item{
	&.menu__item--active{
		.menu__link{
			text-decoration: underline;
		}
	}
}
.pesquisa__expanded{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.mbl__toggler{
	border: none;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 20px;
	background-color: #fff;
	color: #4c2764;

	&:hover{
		animation: rubberBand 1s linear;
	}

	&:focus{
		outline: none;
	}
}
.pesquisa__form{
	display: flex;
	background-color: #fff;

	.pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:transparent;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border: none;
		border-radius: 0px;
		position: relative;
		font-size: 24px;
		line-height: 24px;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'latoregular';
        font-size: 15px;
        line-height: 15px;
        padding: 5px 5px 5px 5px;
        border: none;
        background-color: #fff;
        color: #202020;
        border: none;
        border-radius: 45px;

        &::placeholder{
            color: #202020;
        }

        &:focus{
            outline: none;
        }
    }
}
.bg__menu{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(#202020, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.segura__pesquisa__btn{
	display: flex;
	align-items: center;
	justify-content: center;
}
.sociais__topo{
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		color: #4c2764;
		border-radius: 50%;
		width: 32px;
		height: 32px;
		margin: 0px 2px;
		font-size: 20px;
		line-height: 20px;

		&:hover{
			text-decoration: none;
			animation: rubberBand 1s linear;
		}
	}
}
@include media-breakpoint-up (lg) {
	.topo__main{
		background-color: rgba(#4c2764, 0.6);
		color: #fff;
	}
	.topo{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
	}
	.topo__mobile{
		display: none;
	}
	.main__logo{
		padding: 15px 0px;

		@include media-breakpoint-only (lg) {
			max-width: 130px;
		}
	}
	.segura__pesquisa__btn{
		position: relative;
		margin-left: 41px;

		&:after{
			width: 1px;
			height: 55px;
			position: absolute;
			content: '';
			left: -20px;
			top: 50%;
			transform: translateY(-50%);
			background-color: #fff;
		}
	}
	.topo__main{
		.container{
			display: flex;

			.main__menu{
				margin: 0px 30px;
				display: flex;
				flex-grow: 1;
				justify-content:  space-between;
				font-size: 16px;
				line-height: 18px;
				font-family: 'latolight';

				@include media-breakpoint-only (lg) {
					font-size: 14px;
					line-height: 16px;
				}

				.menu__item{
					display: flex;

					.menu__link{
						display: flex;
						align-items: center;
						justify-content: center;

						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

}
@include media-breakpoint-only (lg) {
	.main__menu{
		font-size: 14px;
		line-height: 16px;

		.menu__link{
			padding: 5px;
			text-align: center;
		}
	}
	.sociais__topo{
		a{
			width: 24px;
			height: 24px;
			font-size: 16px;
			line-height: 16px;
		}
	}
}
.topo{
	&.header--internas{
		top: 0px;
		position: sticky;

		.topo__main{
			background-color: #4c2764;
		}

		.topo__mobile{
			@include media-breakpoint-down (md) {
				position: relative;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.topo__mobile{
		background-color: rgba(#4c2764, 0.6);
		color: #fff;
		position: fixed;
		top: 0px;
		width: 100%;
		padding: 5px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.topo__main{
		width: 250px;
		height: 100%;
		overflow-y: auto;
		z-index: 1080;
		background-color: #4c2764;
		color: #fff;
		left: -250px;
		top: 0px;
		position: fixed;
		transition: all 0.5s linear;

		.segura__pesquisa__btn{
			display: none;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
	}
	.main__logo{
		padding: 10px;
		text-align: center;
		margin-bottom: 10px;
	}
	.sociais__topo{
		margin: 20px 0px;
	}
	.menu__link{
		padding: 10px;
		display: block;
	}
}
