.breadcrump__custom{
	display: flex;
	align-items: center;
	font-family: 'latobold';
	font-style: italic;
	color: #4c2764;
	text-align: right;
	font-size: 20px;
	line-height: 22px;
	margin-bottom: 110px;
	padding-top: 10px;

	&:before{
		width: auto;
		max-width: 100%;
		background-color: currentColor;
		height: 6px;
		content: "";
		display:block;
		margin-right: 80px;
		flex-grow: 1;
	}
}
.historia{
	padding: 70px 0px 170px 0px;
	background-image: url(../images/mapa__equipe.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	color: #fff;
}
.trabalhamos__card{
	display: flex;
	align-items: flex-start;
	font-size: 18px;
	line-height: 20px;

	@include media-breakpoint-down (md) {
		max-width: 300px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.trabalhamos__icone{
		margin-right: 10px;
	}
}
.trabalhamos__icone{
	min-width: 120px;
}
.interna{
	.titulo__sobre{
		font-family: 'latoblack';
	}
}
.historia{
	.segura__titulo{
		position: relative;
		z-index: 100;

		span{
			position: relative;
			z-index: -1;
			bottom: -15px;
		}
	}
	.acreditamos{
		.segura__titulo{
			span{
				position: relative;
				z-index: -1;
				top: -12px;
				bottom: unset;
			}
		}
	}
}
@include media-breakpoint-up (lg) {
	.trabalhamos__listagem{
		.trabalhamos__icone{
			min-width: 120px;
			text-align: right;
		}

		&:after{
			width: 810px;
			height: 135px;
			content: '';
			position: relative;
			display: block;
			background-image: url('../images/detalhe__historia.png');
			background-repeat: no-repeat;
			background-size: auto;
			background-position: right center;
			grid-column: 1 / 4;
			grid-row: 2 / 3;

			@include media-breakpoint-only (lg) {
				width: 600px;
				background-size: 100% auto;
				margin-left: auto;
				margin-right: auto;
			}
		}
		display: grid;
		grid-template-columns: 290px 210px 350px 308px;

		@include media-breakpoint-only (lg) {
			grid-template-columns: 260px 200px 200px 290px;
		}

		.trabalhamos__card{
			&:nth-child(1) {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
			}
			&:nth-child(2) {
				grid-column: 2 / 3;
				grid-row: 3 / 4;
				position: relative;
				margin-left: -120px;
				padding-top: 30px;

				.trabalhamos__desc{
					max-width: 180px;
				}

				@include media-breakpoint-only (lg) {
					margin-left: -200px;
				}
			}
			&:nth-child(3) {
				grid-column: 4 / 5;
				grid-row: 2 / 4;
				flex-direction: column;

				.trabalhamos__icone{
					margin-bottom: 10px;
				}
			}
		}
	}
}
.titulo__historia{
	font-size: 50px;
	line-height: 50px;
	font-family: 'latoblack';
	text-align: center;

	@include media-breakpoint-down (sm) {
		font-size: 40px;
		line-height: 44px;
	}

	.segura__titulo{
		display: inline-block;
		text-align: right;
	}

	span{
		color: #ddb05d;
	}
}
.empresa__conteudo{
	padding-bottom: 70px;
}
.acreditamos{
	padding-top: 80px;
	.titulo__historia{
		margin-bottom: 50px;
		.segura__titulo{
			text-align: left;
		}
	}
}
.acreditamos__card{
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	.acreditamos__icone{
		background-color: #fff;
		border-radius: 50%;
		min-width: 70px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}
}
.acreditamos__grid{
	grid-template-columns: repeat(3, 1fr);
	display: grid;
	font-size: 18px;
	line-height: 20px;

	.acreditamos__card{
		@include media-breakpoint-up (lg) {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3){
				padding-right: 80px;
			}

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6){
				padding-left: 80px;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.trabalhamos__card{
		margin-bottom: 30px;
		flex-direction: column;
		text-align: center;
		align-items: center;

		.trabalhamos__icone{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.titulo__historia{
		margin-bottom: 30px;
	}
}
@include media-breakpoint-down (sm) {
	.acreditamos__grid{
		grid-template-columns: repeat(2, 1fr);
	}
}
@include media-breakpoint-down (sm) {
	.acreditamos__grid{
		display: block;

		.acreditamos__card{
			justify-content: center;
		}
	}
}
.interna__padrao{
	padding-bottom: 180px;
}
.row__cases{
	.col-lg-4{
		margin-bottom: 20px;
	}
}
.clientes__grid{
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 20px;
	row-gap: 20px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(2, 1fr);
	}
}
.paginacao{
	display: flex;
	justify-content: center;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 70px;

	.paginacao__item{
		a{
            font-family: 'latobold';
            font-size: 15px;
            line-height: 15px;
            color: #4c2764;
            display: flex;
            width: 34px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 34px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
			border: none;
            margin: 0px 2px;
            padding: 0px;
            padding-left: 1px;
            padding-top: 1px;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
                background-color: #4c2764;
                color: #fff;
                text-decoration: none;
           }
        }

		&:hover{
			a{
                text-decoration: none;
            }
		}
	}
}
.contato__form{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	max-width: 940px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	column-gap: 20px;
}
.row__servicos{
	.servico__card{
		margin-bottom: 30px;
	}
}
.row__servicos__interna{
	align-items: center;

	.titulo__sobre{
		text-align: left;
		margin-bottom: 20px;
	}
}
.imagem__servico__detalhe{
	text-align: center;
}
.segura__btn__center,
.checkbox__custom,
.segura__mensagem{
	grid-column: span 2;
}
.segura__btn__center{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 30px;
}
.custom__check__input{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .custom__check__label{
			&:after{
				opacity: 1;
			}
		}
	}
}
.custom__check__label{
	position: relative;
	padding-left: 40px;
	cursor: pointer;
	font-family: 'latoregular';
	font-size: 14px;
	line-height: 16px;

	a{
		font-family: 'latobold';
	}

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 2px solid currentColor;
		transition: all 0.3s linear;
	}

	&:after{
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: currentColor;
		top: 50%;
		left: 15px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.contato__input{
	width: 100%;
	display: block;
	border: none;
	padding: 18px 22px;
	border-radius: 30px;
	font-size: 13px;
	line-height: 16px;
	color: #3c3c3b;
	background-color: #ededed;

	&::placeholder{
		color: #3c3c3b;
		font-style: italic;
	}
}
