.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
}
.carousel__servicos{
	.carousel-control-prev__custom,
	.carousel-control-next__custom{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: transparent;
		color: #4c2764;
		border: none;
		transition: all 0.3s linear;

		&:hover{
			color: #ddb05d;
		}
	}
	.carousel-control-prev__custom{
		left: -15px;

		@include media-breakpoint-down (sm) {
			left: -15px;
		}
	}
	.carousel-control-next__custom{
		right: -15px;

		@include media-breakpoint-down (sm) {
			right: -15px;
		}
	}
}
.ipr__equipe{
	padding: 110px 0px 200px 0px;
	background-image: url('../images/bg__equipe.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	color: #fff;
}
.titulo__equipe{
	font-family: 'americanfox';
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 24px;
}
.equipe__desc{
	text-align: justify;
	margin-bottom: 44px;
	font-size: 18px;
	line-height: 22px;
}
.equipe__btns{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.servico__card__grid{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	column-gap: 10px;
	row-gap: 10px;
	min-height: 522px;
}
.servico__card{
	padding: 15px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 256px;
	background-color: #793791;
	color: #fff;
	position: relative;
	overflow: hidden;
	transition: all 0.3s linear;

	&:hover{
		background-color: #ddb05d;
		text-decoration: none;
		color: #fff;

		.servico__card__icone{
			background-color: #fff;
		}

		.servico__card__icone{
			animation: pulinho 1.5s linear infinite;
		}
	}

	&:after{
		width: 150px;
		height: 150px;
		position: absolute;
		content: '';
		left: 50%;
		transform: translateX(-50%);
		top: -75px;
		background-color: rgba(#fff, 0.1);
		border-radius: 50%;
	}

	.servico__card__icone{
		width: 100px;
		height: 100px;
		background-color: #ddb05d;
		mask-position: center;
		mask-size: auto;
		mask-repeat: no-repeat;
		margin-bottom: 10px;
	}
	.servico__card__titulo{
		font-size: 25px;
		line-height: 28px;
		font-family: 'americanfox';
		text-align: center;
	}
}
.conteudo__btn{
	margin: 12px;
	display: inline-flex;
	padding: 10px;
	border: 2px solid currentColor;
	color: currentColor;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 16px;
	font-family: 'latobold';
	border-radius: 33px;
	height: 66px;
	transition: all 0.3s linear;
	min-width: 240px;
	background-color: transparent;

	&.interna__btn{
		background-color: #ddb05d;
		color: #4c2764;
		border-color: #ddb05d;

		&:hover{
			background-color: #4c2764;
			color: #ddb05d;
			border-color: #4c2764;
			text-decoration: none;
		}
	}

	&:hover{
		background-color: #ddb05d;
		color: #4c2764;
		border-color: #ddb05d;
		text-decoration: none;
	}
}
@keyframes pulinho{
	0%{
		transform: translateY(0);
	}
	50%{
		transform: translateY(-10px);
	}
	100%{
		transform: translateY(0);
	}
}
.equipe__carousel{
	display: flex;
	flex-direction: column;

	.carousel-inner{
		order: 1;
	}

	.carousel-indicators{
		order: 2;
		text-indent: 0px;
		bottom: 30px;
		justify-content: center;
		position: relative;
		margin-top: 20px;
		bottom: unset;

		@include media-breakpoint-down (sm) {
			bottom: 5px;
		}

		li{
			width: 20px;
			height: 30px;
			background-color: transparent;
			opacity: 1;
			border: none;
			margin: 0px 4px 0px 4px;
			position: relative;
			border-radius: 16px;
			text-indent: 0px;
			transition: all 0.3s linear;
			position: relative;
			color: #fff;
			text-align: center;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			align-items: center;

			&:after{
				width: 0px;
				margin-left: 5px;
				height: 2px;
				content: '';
				position: relative;
				display: block;
				background-color: currentColor;
				transition: all 0.3s linear;
			}

			&.active{
				width: 50px;
				color: #ddb05d;
				&:after{
					width: 30px;
				}
			}
		}
	}
}
.contato{
	background-color: #ddb05d;
	position: relative;
	z-index: 400;
	margin-top: -100px;
	padding: 42px 15px;
	color: #fff;

	.conteudo__btn{
		&:hover{
			background-color: #4c2764;
			color: #ddb05d;
			text-decoration: none;
		}
	}
}
.contato__col{
	display: flex;
	align-items: center;
	justify-content: center;

	.contato__icone{
		margin-right: 24px;
	}
	.contato__texto__tel{
		font-family: 'americanfox';
		font-size: 48px;
		line-height: 48px;
		margin-bottom: 10px;
	}
}
.contato{
	.contato__descricao{
		font-size: 16px;
		line-height: 18px;
		text-align: justify;
		max-width: 250px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
	.col-lg-7{
		position: relative;

		&:before{
			width: 1px;
			height: 80px;
			background-color: #fff;
			content: '';
			position: absolute;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
		}
		.row{
			align-items: center;
		}
	}
}
.titulo__sobre{
	font-family: 'americanfox';
	font-size: 50px;
	line-height: 54px;
	color: #4c2764;
	margin-bottom: 34px;
	text-align: center;
}
.sobre{
	.container{
		padding-top: 95px;
		padding-bottom: 75px;
		border-bottom: 1px solid #d2d2d2;
	}
}
.depoimento__card{
	&:hover{
		.segura__depoimento__desc{
			border-color: #ddb05d;

			.depoimento__desc{
				&::-webkit-scrollbar-thumb{
					background-color: #ddb05d;
				}
			}
		}
	}
}
.segura__depoimento__desc{
	padding-top: 44px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 84px;
	border: 1px solid #4c2764;
	border-bottom: 25px solid #4c2764;
	transition: all 0.3s linear;

	.depoimento__desc{
		max-height: 221px;
		min-height: 221px;
		overflow-y: auto;

		&::-webkit-scrollbar-thumb{
			background-color: #4c2764;
			border: 2px solid #fff;
			border-radius: 8px;
			transition: all 0.3s linear;
		}
		&::-webkit-scrollbar{
			width: 16px;
		}
	}
}
.depoimento__imagem{
	margin-top: -70px;
	border-radius: 50%;
	overflow: hidden;
	margin-left: 30px;
	margin-bottom: 20px;
	width: 100px;
	height: 100px;
	background-color: #d2d2d2;
}
.depoimento__nome{
	font-family: 'americanfox';
	font-size: 25px;
	line-height: 28px;
	--linhas: 1;
	padding-left: 30px;
}
.depoimento__profissao{
	--linhas: 1;
	color: #ddb05d;
	padding-left: 30px;
}
.owl__custom.owl-carousel{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: transparent;
			color: #4c2764;
			border: none;
			transition: all 0.3s linear;

			&:hover{
				color: #ddb05d;
			}
		}
		button.owl-prev{
			left: -30px;

			@include media-breakpoint-down (sm) {
				left: -15px;
			}
		}
		button.owl-next{
			right: -30px;

			@include media-breakpoint-down (sm) {
				right: -15px;
			}
		}
	}
}
.clientes{
	padding: 80px 0px 70px 0px;
}
.cliente__card{
	border: 1px solid rgba(#000, 0.3);
	display: block;
	max-width: 210px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		border-color: #4c2764;
	}
}
@include media-breakpoint-down (md) {
	.equipe__carousel{
		margin-top: 50px;
	}
}
@include media-breakpoint-down (xs) {
	.servico__card__grid{
		display: block;

		.servico__card{
			margin-bottom: 30px;
		}
	}
}
.video__mensagem{
	font-family: 'latoblack';
	color: #fff;
	text-shadow: 0px 0px 1px rgba(#000, 0.7);
	font-size: 30px;
	line-height: 34px;

	@include media-breakpoint-down (md) {
		background-color: #793791;
		text-align: center;
		--linhas: 10;
		padding: 20px;
	}
	@include media-breakpoint-up (lg) {
		position: absolute;
		top: 50%;
		left: 15%;
		max-width: 900px;
		--linhas: 4;
		z-index: 40;
		font-size: 80px;
		line-height: 84px;
		transform: translateY(-50%);
	}
	@include media-breakpoint-only (lg) {
		font-size: 50px;
		line-height: 54px;
	}
}
.banner__video{
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}
