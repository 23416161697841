.titulo__news{
	font-family: 'latoblack';
	font-size: 25px;
	line-height: 28px;
	margin-bottom: 5px;
}
.news__desc{
	margin-bottom: 10px;
}
.rodape__conteudo{
	background-color: #EDB258;
	color: #fff;
	padding: 64px 0px;

	.row{
		align-items: center;
	}
}
.rodape__logo{
	margin-bottom: 10px;
}
.rodape__desc{
	text-align: justify;
	margin-bottom: 20px;
	font-size: 18px;
	line-height: 22px;
}
.rodape__contato__icone{
	background-color: #4c2764;
	color: #fff;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	line-height: 20px;
	margin-right: 10px;
}
.rodape__contato{
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.rodape__contato__link{
	font-family: 'latoblack';
}

.rodape__contato__conteudo{
	font-size: 18px;
	line-height: 22px;
}
.news__desc{
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 15px;
}
.news__form{
	display: flex;
	align-items: center;
	border-radius: 25px;
	background-color: #fff;
	overflow: hidden;
}
.news__btn{
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background-color: #fff;
	border: none;
	color: #4c2764;
	font-size: 20px;
	line-height: 20px;
}
.news__input{
	display: block;
	width: 100%;
	border: none;
	padding: 16px 14px;
	font-size: 14px;
	line-height: 18px;

	&:focus{
		outline: none;
	}

	&::placeholder{
		font-style: italic;
	}
}
.news__sociais{
	display: flex;
	align-items: center;
	padding-top: 15px;

	.news__sociais__titulo{
		font-family: 'latoblack';
		font-size: 25px;
		line-height: 28px;
		margin-right: 5px;
	}
	.news__sociais__nav{
		display: flex;
		align-items: center;
		justify-content: center;

		a{
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			color: #EDB258;
			border-radius: 50%;
			margin: 0px 2px;
			font-size: 20px;
			line-height: 20px;

			&:hover{
				background-color: #4c2764;
				text-decoration: none;
			}
		}
	}
}
.rodape__creditos{
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	padding: 24px 0px;
	background-color: #4c2764;
	color: #fff;
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content:  center;

	svg{
		margin-left: 5px;
	}
}
.rodape{
	position: relative;
	&.rodape--internas{
		@include media-breakpoint-up (lg) {
			.rodape__conteudo{
				padding-top: 150px;
			}
			.contato{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 0px;
				margin-top: -80px;

				.conteudo__btn{
					&:hover{
						color: #4c2764;
						background-color: #EDB258;
						border-color: #EDB258;
					}
				}
			}
		}
		.contato{
			background-color: #4c2764;
		}
	}
}
