@font-face {
    font-family: 'americanfox';
    src: url('../fonts/americanfox/AmericanFoxRegular.eot');
    src: url('../fonts/americanfox/AmericanFoxRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/americanfox/AmericanFoxRegular.woff2') format('woff2'),
        url('../fonts/americanfox/AmericanFoxRegular.woff') format('woff'),
        url('../fonts/americanfox/AmericanFoxRegular.ttf') format('truetype'),
        url('../fonts/americanfox/AmericanFoxRegular.svg#AmericanFoxRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black/Lato-Black.eot');
    src: url('../fonts/lato-black/Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato-black/Lato-Black.woff2') format('woff2'),
        url('../fonts/lato-black/Lato-Black.woff') format('woff'),
        url('../fonts/lato-black/Lato-Black.ttf') format('truetype'),
        url('../fonts/lato-black/Lato-Black.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold/Lato-Bold.eot');
    src: url('../fonts/lato-bold/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato-bold/Lato-Bold.woff2') format('woff2'),
        url('../fonts/lato-bold/Lato-Bold.woff') format('woff'),
        url('../fonts/lato-bold/Lato-Bold.ttf') format('truetype'),
        url('../fonts/lato-bold/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'latolight';
    src: url('../fonts/lato-light/Lato-Light.eot');
    src: url('../fonts/lato-light/Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato-light/Lato-Light.woff2') format('woff2'),
        url('../fonts/lato-light/Lato-Light.woff') format('woff'),
        url('../fonts/lato-light/Lato-Light.ttf') format('truetype'),
        url('../fonts/lato-light/Lato-Light.svg#Lato-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular/Lato-Regular.eot');
    src: url('../fonts/lato-regular/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/lato-regular/Lato-Regular.woff2') format('woff2'),
        url('../fonts/lato-regular/Lato-Regular.woff') format('woff'),
        url('../fonts/lato-regular/Lato-Regular.ttf') format('truetype'),
        url('../fonts/lato-regular/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
